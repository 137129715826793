.mains
{
    display: inline-block;
    width: 100%;
    height: auto;
    margin: auto;
    text-align: center;
    margin-bottom: 10%;
}

.title img 
{
    max-width: 9em;
    max-height: 9em;
}

.title h1
{
    font-size: 2.5em;
    color: #2f5597;
    font-weight: bold;
}


.preguntas
{
    font-size: 1.4rem;
    padding-left: 20%;
    padding-right: 20%;
    text-align: left;
}

summary
{
    font-weight: bold;
}

.preguntas h2
{
    margin-top: 2%;
    font-size: 2rem;
    color: #2f5597;
    font-weight: bold;
}

b
{
    padding-left: 10px;
    font-size: 1.4rem;
}

.preguntas p
{
    font-size: 1.4rem;
}

.a_click
{
    font-size: 1.5rem;
}

.a_click:-webkit-any-link
{
    font-size: 1.5rem;
    color: black;
}

@media (max-width:768px)
{
    .preguntas
    {
        font-size: 1.4rem;
        padding-left: 5%;
        padding-right: 5%;
        text-align: left;
    }
}