.body-portal 
{  
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1.5fr 0.5fr;
    grid-auto-rows: 1fr;
    gap: 12px 12px;
    grid-auto-flow: row;
}

.first-portal 
{  
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 500px;
    gap: 12px 12px;
    grid-auto-flow: row;
}

.second-portal 
{  
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 12px 12px;
    grid-auto-flow: row;
}

.edit
{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.portal-icons
{
    width: 200px;
    padding: 30px;
}

.cp 
{
    display: grid;
    place-content: center;
    text-align: center;
}

.video
{
    width: 600px;
    height: 380px;
}

.portal-a
{
    text-decoration: none;
}


@media (max-width: 768px)
{
    .body-portal
    {
        grid-template-rows: 1fr 0.5fr;
    }
    .first-portal 
    {
        grid-template-columns: repeat(1,1fr);
        grid-template-rows: repeat(1,200px 500px);
    }
    .portal-title
    {
        height: 200px;

    }

    .title_portal
    {
        font-size: calc(1em + 5vw);
    }

    .second-portal
    {
        grid-template-columns: repeat(2,1fr);
    }
    .video
    {
        width: 100%;
        height: 380px;
    }

    .portal-icons
    {
        width: 100px;
        padding: 10px;
    }
}