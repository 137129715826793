.icon
{
    width: 70%;
}

.navbar
{
    height: 10rem;
    
}

.nav-link::first-letter
{
    color:#2f5597;
    font-weight: bold ;
}

.navbar-collapse
{
    position: relative;
}

.nav-link
{
    color:#75ac49;
    margin: 10px;
    font-weight: bold ;
    font-size: 1.3rem;
    position: relative;
}



@media (max-width: 768px)
{

    .navbar
    {
        width: 100%;
        padding: 20px;
    }

    .navbar-brand
    {
        width:calc(1em + 58vw);
    }

    .nav-link::first-letter
    {
        color:#2f5597;
        font-weight: bold ;
        text-transform: capitalize;
    }
    .nav-link
    {
        text-transform: lowercase;
    }


}
