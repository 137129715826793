
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.h4 
{
    font-weight: normal !important;
}

.body
{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr; 
    grid-template-rows: calc(30em + 4vw) 1fr; 
    gap: 12px 0px; 
    
}

.body_main_color
{
    background-color: white;
    color: #2f5597;

}

.body_main
{
    padding: 4em;
    height: calc(30em + 5vw);
    width: 100%;
    background-image: url("../imgs/fondo.webp");
    background-position: center 0;
    background-repeat: no-repeat;
    color: #2f5597;
}

.body_main p
{
    justify-content: center;
    width: 60%;
    font-size: 1.3rem;
}

.nav
{
    justify-content: center;
}

h1
{
    font-size: 4rem;
}

.nav-pills .nav-link::first-letter
{
    color: #75ac49; 
}


.nav-pills .nav-link.active
{
    background-color: #2f5597; 
}

.nav-pills .nav-link.active::first-letter
{
    color: white; 
}

.card
{
    position: initial !important;
}

.img-fluid
{
    max-height: 700px;
}
@media (max-width: 768px)
{

    .title_boddy
    {
        font-size: calc(3em + 1vw);
    }


    .body
    {
        grid-template-rows: calc(28em + 40vw) 1fr; 
        width: 100%;
    }

    .body_main
    {
        padding: 1em;
        width: 100%;
    }

    .body_main p
    {
        font-size: calc(1em + 1vw);
        width: 100%;
        text-align: justify !important; 
    }

    .body_main
    {
        height: 0px;
    }

    .card-text
    {
        text-align: justify !important; 
    }
}