

.body-about
{  
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: repeat(2,1fr);
    gap: 20px 0px;

} 

.color_black
{
    color: black;
    backdrop-filter: blur(10px);
}

.i5 
{  
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 100px;
    grid-auto-flow: row;
}

.card
{
    text-align: center;
    justify-content: center;
}

.about
{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 100px auto;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    
}

.carousel__container {
    /* max-width constrains the width of our carousel to 550, but shrinks on small devices */
    max-width: 550px;
    margin: auto;
  }
  
.color_number
{
    color: #2f5597;
}

.slide_div
{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
}

.card_slide
{
    width: 60%;
}

@media (max-width: 1800px) 
{
    .slide
    {
        height: 100px !important;

    }
}


@media (max-width: 768px)
{
    .body-about
    {  
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: repeat(2, 1fr);
        gap: 20px 0px;

    } 

    .i5 
    {  
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: 20px 0px;
        grid-auto-flow: row;
    }
    
    .slide
    {
        height: 100px !important;

    }

}