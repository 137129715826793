.body_vacantes
{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1000px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.table_vacantes
{
    margin: 20px;
    display: grid;
    align-items: center;
    justify-items: center;
}

.celdas
{
    padding: 20px;
    font-size: calc(.7em + .6vw);
}

.titulos
{
    text-align: center;
    font-size: calc(.7em + .8vw);
}

.registra_hoja
{
    margin-top: 50px;
    text-align: center;
}

.registra_hoja h1 
{
    font-size: calc(.7em + 2vw);;
}

.registra_hoja p
{
    font-size: calc(.7em + 1vw);
}

.frame
{
    height: 600px;
    width: 40%;
}

@media (max-width: 768px)
{
    .body_vacantes
    {
        grid-template-rows: 1fr 450px;
    }

    .celdas
    {
        padding: 20px;
        font-size: calc(.4em + .8vw);
    }

    .titulos
    {
        text-align: center;
        font-size: calc(.7em + .4vw);
    }

    .btn-info
    {
        font-size: calc(.4em + .4vw);
    }

    .frame
    {
        height: 250px;
        width: 100%;
    }

}