.mainn {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* grid-template-rows: 300px 600px 600px 600px 300px 600px 600px; */
    grid-auto-rows:repeat(8, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    text-align: center;
    grid-auto-flow: dense;
}
    

.div2_notic
{ 
    grid-area: 2 / 2 / 3 / 4;
    text-align: start;
}


.section_one 
{ 
    grid-area: 1 / 2 / 2 / 4;
    padding: 20px;
}

.img_title
{
    width: 50%;
}

.h1_noticia
{
    font-size: calc(2em + 1vw);
    color:#75ac49;
    font-weight: bold;
}

.subtitle_notic
{
    margin-top: 2vw;
    font-size: calc(.7em + .4vw);
    color:#75ac49;
    font-weight: bold;

}

.p_notic
{
    font-size: calc(.8em + .8vw);
    padding: 0px 20px 0px 20px;
    text-align: start;
}

.lado_imagen_uno
{ 
    grid-area: 3 / 2 / 5 / 3;
    padding: 20px;
    text-align: start;
}

.divisor_imagen 
{
    grid-area: 5 / 2 / 6 / 4; 
    
}

.imagen_uno 
{ 
    grid-area: 3 / 3 / 5 / 5;
    text-align: center; 
    align-content: center;
}

.imagen_uno img
{
    height: 80%;
    width: 80%;
    padding: 100px;
    border-radius: 1000px;
    object-fit: fill;
}

.ul_decimal
{
    text-align: start;
    list-style: decimal;
}

.lado_imagen_dos
{
    grid-area: 6 / 3 / 8 / 4;
}

.imagen_dos 
{ 
    grid-area:6 / 2 / 8 / 3;
    text-align: center; 
    align-content: center;
}

.imagen_dos img
{
    height: 80%;
    width: 100%;
    padding: 10px;
    border-radius: 1000px;
    object-fit: fill;
}

.final_blog 
{ 
    grid-area: 8 / 2 / 9 / 4;
}

@media (max-width: 768px)
{

    .section_one
    {
        grid-area: 1 / 1 / 2 / 5;
    }

    .img_title
    {
        width: 80%;
    }

    .div2_notic
    {
        grid-area: 2 / 1 / 3 / 5;
    }
    .lado_imagen_uno
    { 
        grid-area: 4 / 1 / 5 / 5;
    }

    .imagen_uno
    {
        grid-area: 5 / 1 / 6 / 5;
    }

    .imagen_uno img
    {
        width: 100%;
        height: 100%;
        border-radius: 0px;
        padding: 50px;
    }

    .divisor_imagen
    {
        grid-area: 6 / 1 / 7 / 5;
    }

    .lado_imagen_dos
    {
        grid-area: 7 / 1 / 8 / 5;
    }
    
    .imagen_dos
    {
        grid-area: 8 / 1 / 9 / 5;
    }

    .final_blog
    {
        grid-area: 9 / 1 / 9 / 5;
    }

}