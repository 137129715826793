input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

/* input[type=number] { -moz-appearance:textfield; } */

.col
{
    font-size: 1.4rem;
    padding: 20px;
    border: 1px solid green;
}

.col1
{
    text-align: end;
    font-weight: bold;
}

.carousel-caption
{
    color:white !important;
    top:15%;
    /* border:1px solid black; */
    /* background-color: white; */
    bottom: 10.25rem !important;
}

.img_areas
{
    border-radius: 10px;
    filter:brightness(70%);
}

.img_icon
{
    width: 90px;
    margin: 20px;
}

.tit_areas
{
    font-size: 3vw;
    font-weight: bold;
    padding-bottom: 10px;
}

.descrip
{
    font-size: 1.2vw;
}

.informacion
{
    width: 100%;
    height: 800px;
}

.info
{
    text-align: center;
}

.info h2
{
    font-size: 2.2rem;
}

.info p
{
    font-size: 1.4rem;
}

.p_first
{
    padding-top:20px;
}

.card_contacto
{
    width: 70%;
    /* min-width: 550px; */
    font-size: 2rem;
}

.forms
{
    font-size: 1.8rem !important;
}

.forms_msg
{
    height: 200px;
}

.mapa
    {
        width: 100%;
    }

.btn_enviar
{
    font-size: 2rem !important;
}

.ubicacion
{
    display: grid;
    margin-top: 40px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

}

.contenedor_titulo
{
    background-color: #2f5597;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width:768px)
{
    .col
    {
        font-size: 1.1rem;
        
    }

    .info p
    {
        font-size: .9rem;
    }

    .info h2
    {
        font-size: 1.7rem;
    }

    .mapa
    {
        width: 100%;
    }

    .w-75
    {
        height: 400px;
    }

    .img_icon
    {
        width: 40px;
    }

    .tit_areas
    {
        font-size: 6vw;
    }

    .descrip
    {
        font-size: 3vw;
    }

    .informacion
    {
        width: 100%;
        height: 12000px;
    }

    .carousel-caption
    {
        top:0;
    }

    .card_contacto
    {
        height: 1000px !important;
    }

    .btn_po
    {
        width: 100px;
        height: 20px;
        padding: 0;
    }

    .btn_po a
    {
        font-size: 1.1rem;
    }
    
    .contactacnos_conetenedor
    {
        height: 400px;
    }

    .carousel-control-prev,.carousel-control-next,.carousel-indicators
    {
        position: relative;
        display: none;
    }

}