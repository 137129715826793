.parent 
{
    margin:30px;
    width: 50%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-auto-flow: column;
    
}

.div1s img, .div2s img, .div3s img, .div4s img, .div5s img, .div6s img
{
    margin-bottom: 20px;
}

.div1s 
{ 
    grid-area: 1 / 1 / 2 / 2; 
    border-bottom:5px solid black;
    text-align: center;
    padding: 10px;
}

.div2s 
{ 
    grid-area: 1 / 2 / 2 / 3; 
    border-bottom:5px solid black;
    text-align: center;
    padding: 10px;
}
.div3s 
{ 
    grid-area: 1 / 3 / 2 / 4; 
    border-bottom:5px solid black;
    text-align: center;
    padding: 10px;
}
.div4s 
{ 
    grid-area: 2 / 1 / 3 / 2; 
    text-align: center;
    padding: 10px;
}
.div5s 
{ 
    grid-area: 2 / 2 / 3 / 3; 
    text-align: center;
    padding: 10px;
}
.div6s 
{ 
    grid-area: 2 / 3 / 3 / 4; 
    text-align: center;
    padding: 10px;
}

.div1s:hover, .div2s:hover ,.div3s:hover, .div4s:hover, .div5s:hover, .div6s:hover
{
    border-radius: 10px;
    background-color: rgb(46,85,147);
    transition: 2s;
    color: white;
    zoom: 1.1;
}

.div1s:hover img, .div2s:hover img, .div3s:hover img, .div4s:hover img, .div5s:hover img, .div6s:hover img
{
    background-color: white;
    border-radius: 100px;
    border-image: 100px;
}

.services_icons
{
    width: 150px;
    padding: 20px;
}


.ls_services
{

    text-align: left;
}

.ls_services > li
{
    font-size: 1.2em;
}

@media(max-width:768px) 
{
    .parent 
    {
        margin:0px;
        width: 80%;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        font-size: calc(.5em + .5vw);
    }

    .div1s
    {
        grid-area: 1 / 1 / 2 / 2;
    }
    .div2s
    {
        grid-area: 1 / 2 / 2 / 3;
    }
    .div3s
    {
        grid-area: 2 / 1 / 3 / 2;
    }
    .div4s
    {
        grid-area: 2 / 2 / 3 / 3;
        border-bottom:5px solid black;
    }
    .div5s
    {
        grid-area: 3 / 1 / 4 / 2;
    }
    .div6s
    {
        grid-area: 3 / 2 / 4 / 3;
    }

    .services_icons
    {
        width: 120px;
        padding: 20px;
    }

    .titulos_servicios
    {
        font-size: calc(1em + 1vw);
        font-weight: bold;
    }

}

